.qrCode {
    display: block;
    z-index: 999999999;
    position: fixed;
    left: 0;
    bottom: 313px;
    width: 120px;
    height: 185px;
    background-color: #fff;
    & img {
        float: left;
        display: block;
    }
}

.closeBtn {
    float: left;
    color: #999;
    cursor: pointer;
    height: 18px;
    line-height: 18px;
    padding-left: 4px;
}
