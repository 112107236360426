.view_points {
    overflow: hidden;
    margin-bottom: 20px;
}
.person_view {
    width: 360px;
    float: left;
}
.person_title {
    margin-left: 4px;
    margin-bottom: 6px;
}
.person_view ul{
    overflow: hidden;
}
.person_view ul li {
    float: left;
    margin-right: 20px;
    width: 170px;
    height: 188px;
}
.person_view ul li:nth-child(2n){
    margin-right: 0;
}
.person_view ul li img {
    width: 170px;
    height: 114px;
}
.person_view ul li p {
    margin: 6px 0 14px;
    line-height: 20px;
}
.view_right {
    width: 360px;
    float: right;
}
.dujia_view {
    overflow: hidden;
}
.dujia_title {
    margin-left: 12px;
    margin-bottom: 6px;
}
.dujia_view ul li {
    float: left;
    margin-right: 20px;
    width: 255px;
    height: 222px;
}
.dujia_view ul li img {
    width: 255px;
    height: 170px;
}
.dujia_view ul li p {
    margin: 6px 0 14px;
    line-height: 20px;
}

.chenzhan_view {
    overflow: hidden;
}
.chenzhan_view ul li {
    float: left;
    width: 170px;
    margin-right: 20px;
}
.chenzhan_view ul li:nth-child(2n){
    margin:0;
}
.chenzhan_view ul li img {
    width: 170px;
    height: 114px;
}
.chenzhan_view ul li p {
    margin: 10px 0 20px;
    line-height: 20px;
}
