.ad {
    width: 1090px;
    margin: 37px auto 0;
}

.box {
    width: 1090px;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    padding: 0;
    margin: 0;
}

.footer {
    background: none;
    width: 100%;
    margin: 50px auto 0;
    height: 80px;
}
