.title {
    border-bottom: 1px solid #ececec;
    color: #3b3732;
    font-size: 26px;
    font-weight: bold;
    padding: 10px 0;
    font-family: Microsoft YaHei;
}
.cut {
    height: 26px;
    width: 8px;
    background-color: #d7524b;
    margin-right: 15px;
    display: inline-block;
    vertical-align: middle;
}
.name {
    vertical-align: middle;
}
