.col {
    composes: clearfix from global;
    width: 1090px;
    margin: 0 auto;
    background: #fff;
}

.select_search {
    width: 100%;
    height: 48px;
}

.col_L {
    composes: clearfix from global;
    width: 700px;
    float: left;
}

.ad {
    width: 640px;
    margin: 36px 0px;
}

.box {
    position: relative;
    width: 640px;
    text-align: center;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.brand_bg {
    width: 140px;
    float: right;
}

.brand_bg a {
    font-size: 16px;
    font-weight: bold;
    color: #969696;
    text-align: center;
    border: 2px solid #dedddd;
    background-color: #fff;
    display: block;
    height: 32px;
    line-height: 32px;
    margin: 6px 0;
}

.brand_bg a:hover {
    text-decoration: none;
    color: #fff;
    background-color: #f54343;
}

.news111 {
    overflow: hidden;
    margin-bottom: 5px;
}

/* .banner_ad {
    margin: 0 auto 28px;
    width: 1090px;
    height: 91px;
} */

.banner_ad_div {
    width: 1090px;
}