.enquiry_box_cover {
    width: 100%;
    height: 100%;
    background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
    z-index: 28900;
}
.enquiry_box {
    width: 549px;
    height: 438px;
    background-color: #f8f8f8;
    border: 3px solid #cf0000;
    border-top: 0;
    position: fixed;
    z-index: 29000;
    top: 50%;
    left: 50%;
    margin-top: -274px;
    margin-left: -219px;
}

.enquiry_box .tit {
    width: 100%;
    height: 45px;
    line-height: 45px;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    background-color: #cf0000;
}

.enquiry_box .tit .close {
    width: 16px;
    height: 16px;
    background: url(http://y1.ifengimg.com/auto/image/2014/0827/b.png) no-repeat -114px -6px;
    position: absolute;
    right: 11px;
    top: 11px;
    cursor: pointer;
}

.enquiry_box .enqimg1 {
    width: 193px;
    height: 122px;
    background: url(http://y0.ifengimg.com/auto/image/2015/1103/enquiry_2.png) no-repeat 0 0;
    position: absolute;
    top: -77px;
    left: -39px;
    z-index: 1;
}

.enquiry_box .enqimg2 {
    width: 295px;
    height: 66px;
    background: url(http://p1.ifengimg.com/auto/image/2017/1103/enquiry_3.png) no-repeat 0 0;
    position: absolute;
    bottom: -22px;
    right: -25px;
    z-index: 1;
}

.enquiry_box .con .row {
    margin-top: 28px;
    text-align: center;
}

.enquiry_box .con .row select {
    width: 135px;
    text-align: left;
    font: 14px/36px Microsoft YaHei;
    color: #585858;
    margin-right: 16px;
}

.enquiry_box .con .row .cr_l {
    width: 376px;
    text-align: left;
    float: left;
    margin-left: 33px;
}

.enquiry_box .con .row .cr_l p {
    margin-bottom: 20px;
}

.enquiry_box .con .row .cr_l .r {
    font: bold 14px Microsoft YaHei;
    color: #cf0000;
    margin-right: 10px;
}

.enquiry_box .con .row .cr_l select {
    border: 1px solid #b6b6b6;
    width: 120px;
    height: 24px;
    margin: 0 14px 0 -4px;
    left: 0;
    text-align: center;
    font: 14px/36px Microsoft YaHei;
    color: #585858;
}

.enquiry_box .con .row .cr_l .m {
    font: 12px/12px Microsoft YaHei;
    color: #929292;
}

.enquiry_box .con .row .cr_l input[type='text'] {
    border: 1px solid #b6b6b6;
    widows: 175px;
    height: 36px;
    font: 16px/32px Microsoft YaHei;
    padding-left: 5px;
}

.enquiry_box .con .row .cr_l .get_btn {
    width: 170px;
    height: 40px;
    background-color: #cf0000;
    font: bold 24px/36px Microsoft YaHei;
    text-align: center;
    color: #fff;
    cursor: pointer;
    margin: 3px 0 0 100px;
}

.enquiry_box .con .row .cr_l .w_b {
    color: #999;
    font: 12px/28px SimSun;
    text-align: left;
    margin-top: 12px;
}

.enquiry_box .con .row .cr_r {
    width: 116px;
    float: left;
    text-align: center;
}

.enquiry_box .con .row .cr_r img {
    display: inline-block;
    display: inline;
    zoom: 1;
}

.enquiry_box .con .row .cr_r span {
    font: 12px/16px SimSun;
    color: #686868;
}

.enquiry_box .con .row .cr_r .r {
    color: #cf0000;
}

.enquiry_b {
    border: 3px solid #cf0000;
    width: 669px;
    position: fixed;
    top: 0;
    left: 50%;
    margin-left: -338px;
    background-color: #f8f8f8;
    z-index: 999999;
}

.enquiry_b .tit {
    background-color: #cf0000;
    height: 42px;
    font: 24px/42px Microsoft YaHei;
    color: #fff;
    text-align: center;
    position: relative;
}

.enquiry_b .tit .close {
    width: 16px;
    height: 16px;
    background: url(http://y1.ifengimg.com/auto/image/2014/0827/b.png) no-repeat -114px -6px;
    position: absolute;
    right: 11px;
    top: 11px;
    cursor: pointer;
}

.enquiry_b .tit .go_on {
    width: 80px;
    height: 24px;
    position: absolute;
    bottom: -24px;
    *bottom: -32px;
    right: 10px;
    cursor: pointer;
}

.enquiry_b .tit .go_on i {
    width: 16px;
    height: 23px;
    display: inline-block;
    background: url(http://y1.ifengimg.com/auto/image/2014/0827/b.png) no-repeat -134px -2px;
}

.enquiry_b .tit .go_on span {
    font: 14px/23px Microsoft YaHei;
    color: #959595;
}

.enquiry_b .con {
    background-color: #f8f8f8;
    padding: 0 15px 27px;
}

.enquiry_b .con .row {
    width: 639px;
    height: 30px;
}

.enquiry_b .con .row {
    text-align: left;
}

.enquiry_b .con .row .sel_val_brand {
    border: 1px solid #dedede;
    background-color: #fff;
    width: 135px;
    height: 23px;
    font: 14px/23px Microsoft YaHei;
    color: #585858;
    text-align: center;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    margin-right: 17px;
    margin-bottom: 4px;
    *margin-right: 10px;
    *margin-top: 3px;
    overflow: hidden;
    filter: alpha(opacity=70);
    opacity: 0.7;
    position: relative;
}

.enquiry_b .con .row .sel_val_serie {
    border: 1px solid #dedede;
    background-color: #fff;
    width: 168px;
    height: 23px;
    font: 14px/23px Microsoft YaHei;
    color: #585858;
    text-align: center;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    margin-right: 17px;
    margin-bottom: 4px;
    *margin-right: 10px;
    *margin-top: 3px;
    overflow: hidden;
    filter: alpha(opacity=70);
    opacity: 0.7;
    position: relative;
}

.enquiry_b .con .row .sel_val_cartype {
    border: 1px solid #dedede;
    background-color: #fff;
    width: 204px;
    height: 23px;
    font: 14px/23px Microsoft YaHei;
    color: #585858;
    text-align: center;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    margin-bottom: 4px;
    overflow: hidden;
    filter: alpha(opacity=70);
    opacity: 0.7;
    position: relative;
}

.enquiry_b .con .row .sel_val_brand s,
.enquiry_b .con .row .sel_val_serie s,
.enquiry_b .con .row .sel_val_cartype s {
    width: 23px;
    height: 23px;
    background: url(http://y1.ifengimg.com/auto/image/2014/0827/b.png) no-repeat -81px -2px;
    text-indent: -9999px;
    overflow: hidden;
    float: right;
}

.enquiry_b .con .row .sel_close {
    width: 48px;
    height: 23px;
    font: 14px/23px Microsoft YaHei;
    color: #a6a6a6;
    text-align: center;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    margin-bottom: 4px;
    margin-left: 14px;
    *margin-left: 8px;
    overflow: hidden;
    cursor: pointer;
}

.enquiry_b .con .row .sel_close i {
    width: 13px;
    height: 13px;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    background: url(http://y1.ifengimg.com/auto/image/2014/0827/b.png) no-repeat -67px 0;
    vertical-align: middle;
    margin-right: 3px;
}

.enquiry_b .clear {
    clear: both;
}

.enquiry_b .screening-item {
    display: inline-block;
    *display: block;
    *zoom: 1;
    *float: left;
    margin-right: 10px;
    position: relative;
    z-index: 999999;
}

.enquiry_b .add-item {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: super;
    *vertical-align: middle;
    cursor: pointer;
}

.enquiry_b .add-item {
    font: 14px/14px Microsoft YaHei;
    color: #a6a6a6;
}

.enquiry_b .add-item i {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    width: 13px;
    height: 13px;
    background: url(http://y1.ifengimg.com/auto/image/2014/0827/b.png) no-repeat -52px 0;
    vertical-align: middle;
    margin-right: 3px;
}

.enquiry_b .add-item:hover {
    color: #cf0000;
}

.enquiry_b .add-item:hover i {
    background: url(http://y1.ifengimg.com/auto/image/2014/0827/b.png) no-repeat -52px -15px;
}

.enquiry_b .seldiv {
    height: 23px;
    border: 1px solid #dedede;
    font: 14px/23px Microsoft YaHei;
    color: #585858;
    text-align: center;
    cursor: pointer;
}

.enquiry_b .seldiv s {
    display: block;
    width: 23px;
    height: 23px;
    background: url(http://y1.ifengimg.com/auto/image/2014/0827/b.png) no-repeat -81px -2px;
    float: right;
    cursor: pointer;
    text-indent: -9999px;
    overflow: hidden;
}

.enquiry_b .sel_brand {
    width: 130px;
}

.enquiry_b .sel_serie {
    width: 163px;
}

.enquiry_b .sel_cartype {
    width: 199px;
}

.enquiry_b .mt38 {
    margin-top: 38px;
}

.enquiry_b .mb15 {
    margin-bottom: 15px;
}

.enquiry_b .con_row {
    width: 639px;
    border-top: 1px solid #dadada;
    position: relative;
    margin-top: 38px;
}

.enquiry_b .con_row .pencil {
    width: 50px;
    height: 28px;
    background: url(http://y1.ifengimg.com/auto/image/2014/0827/b.png) no-repeat 0 0;
    position: absolute;
    top: -14px;
    left: 0;
}

.enquiry_b .con_row .cr_tit {
    width: 342px;
    height: 28p;
    font: 14px/28px Microsoft YaHei;
    color: #8e8e8e;
    position: absolute;
    top: -14px;
    left: 172px;
    text-align: center;
    background-color: #f8f8f8;
}

.enquiry_b .con_row .cr_tit b {
    color: #cf0000;
    font-weight: bold;
}

.enquiry_b .con_row .cr_l {
    width: 495px;
    float: left;
    text-align: left;
    margin-top: 28px;
}

.enquiry_b .con_row .cr_l input[type='text'] {
    border: 1px solid #b6b6b6;
    widows: 175px;
    height: 36px;
    font: 16px/32px Microsoft YaHei;
    padding-left: 5px;
}

.enquiry_b .con_row .cr_l input[type='radio'] {
    vertical-align: middle;
}

.enquiry_b .con_row .cr_l label {
    font: bold 14px/36px Microsoft YaHei;
    color: #686868;
    margin-right: 10px;
}

.enquiry_b .con_row .cr_l .r {
    font: bold 14px Microsoft YaHei;
    color: #cf0000;
    margin-right: 10px;
}

.enquiry_b .con_row .cr_l select {
    border: 1px solid #b6b6b6;
    width: 122px;
    height: 24px;
    margin: 0 10px 0 -7px;
    left: 0;
    text-align: center;
    font: 14px/36px Microsoft YaHei;
    color: #585858;
}

.enquiry_b .con_row .cr_l .m {
    font: 12px/12px Microsoft YaHei;
    color: #929292;
}

.enquiry_b .con_row .cr_r {
    width: 144px;
    float: left;
}

.enquiry_b .con_row .cr_r img {
    width: 116px;
    height: 113px;
    margin: 25px auto 0;
}

.enquiry_b .con_row .cr_r .words {
    font: 12px/18px SimSun;
    color: #8e8e8e;
    text-align: center;
}

.enquiry_b .con_row .cr_r .words p {
    color: #cf0000;
}

.enquiry_b .con_row .get_btn {
    font: bold 24px/40px Microsoft YaHei;
    color: #fff;
    text-align: center;
    margin: 18px auto 22px;
    width: 170px;
    height: 40px;
    cursor: pointer;
    background-color: #cf0000;
}

.enquiry_b .con_row .w_b {
    text-align: left;
    font: 12px/28px SimSun;
    color: #999;
}

#enquiry_box_cover {
    width: 100%;
    height: 0px;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
    z-index: 28900;
}

.enquiry_b .con .b_tit {
    width: 669px;
    height: 38px;
    font: 36px/36px Microsoft YaHei;
    color: #000;
    text-align: center;
    margin-top: 43px;
}

.enquiry_b .con .s_tit {
    width: 610px;
    height: 16px;
    font: 14px/16px Microsoft YaHei;
    color: #777;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
    padding: 14px 0 26px;
    margin: 0 auto;
}

.enquiry_b .con .s_tit a {
    font: 14px/16px Microsoft YaHei;
    color: #cf0000;
    text-decoration: none;
}

.enquiry_b .con .s_tit a:hover {
    color: #cf0000;
    text-decoration: underline;
}

.enquiry_b .con .native {
    width: 375px;
    margin: 0 auto;
}

.enquiry_b .con .native .nat_tit {
    width: 375px;
    font: bold 14px/14px Microsoft YaHei;
    text-align: left;
    margin: 22px 0 7px 0;
}

.enquiry_b .con .native ul li {
    width: 375px;
    font: 12px/26px Microsoft YaHei;
    color: #6f6f6f;
    text-align: left;
}

.enquiry_b .con .native ul li a {
    font: 12px Microsoft YaHei;
    color: #6f6f6f;
}

.enquiry_b .con .native ul li i {
    width: 7px;
    height: 7px;
    background: url(http://y1.ifengimg.com/auto/image/2014/0827/b.png) no-repeat -154px -2px;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    margin-right: 8px;
    vertical-align: middle;
}

.enquiry_b .con .native .dealer {
    margin-top: 25px;
}
.enquiry_b .con .native .dealer_name {
    font: bold 18px/28px Microsoft YaHei;
    color: #333;
    width: 222px;
    margin-top: 3px;
}

.enquiry_b .con .native .dealer img {
    width: 120px;
    height: 80px;
    float: left;
}

.enquiry_b .con .native .dealer .dealer_con {
    width: 222px;
    margin-left: 28px;
    text-align: left;
    float: left;
}

.enquiry_b .con .native .dealer .dealer_con .deal_tit {
    font: 14px/22px Microsoft YaHei;
    color: #333;
    width: 222px;
}

.enquiry_b .con .native .dealer .dealer_con .deal_name {
    font: bold 18px/28px Microsoft YaHei;
    color: #333;
    width: 222px;
    margin-top: 3px;
}

.enquiry_b .con .native .dealer .dealer_con .deal_name a {
    font: bold 18px/28px Microsoft YaHei;
    color: #333;
}

.enquiry_b .con .native .dealer .dealer_con .deal_info {
    font: 14px/22px Microsoft YaHei;
    color: #0b6100;
    width: 222px;
}

.enquiry_b .con .succeed_tit {
    width: 669px;
    height: 38px;
    font: 36px/36px Microsoft YaHei;
    color: #da0000;
    text-align: center;
    margin-top: 43px;
}

.enquiry_b .con .succeed_s {
    width: 610px;
    height: 16px;
    font: 14px/16px Microsoft YaHei;
    color: #666;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
    padding: 14px 0 26px;
    margin: 0 auto;
}

.enquiry_b .con .succeed_s a {
    font: 14px/16px Microsoft YaHei;
    color: #666;
    margin-right: 6px;
}

.enquiry_b .con .succeed_s a:hover {
    color: #da0000;
}

.clear {
    clear: both;
}
.agree a{
    color: #cf0000;
    cursor: pointer;
}
.agreechk{
    margin-right: 5px;
    vertical-align: middle;
}