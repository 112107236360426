.enquiry_bottom_bar {
    width: 100%;
    height: 58px;
    position: fixed;
    z-index: 29000;
    left: 0;
    bottom: 0;
}
.enquiry_bottom_bar .ebb_mask {
    width: 100%;
    height: 56px;
    border-top: 1px solid #141414;
    border-bottom: 1px solid #141414;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.75;
    filter: alpha(opacity=75);
}
.enquiry_bottom_bar .enquiry_bar_con {
    width: 1000px;
    height: 58px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}
.enquiry_bottom_bar .enquiry_bar_con i {
    display: block;
    width: 346px;
    height: 122px;
    background: url(http://p1.ifengimg.com/auto/image/2017/1103/enquiry_1.png) no-repeat 0 0;
    position: absolute;
    left: 0;
    bottom: 0;
}
.enquiry_bottom_bar .enquiry_bar_con .enquiry_bar_data {
    width: 632px;
    height: 58px;
    margin-left: 367px;
    font-size: 0;
    text-align: left;
}
.enquiry_bottom_bar .enquiry_bar_con .enquiry_bar_data select {
    width: 131px;
    height: 31px;
    border: 1px solid #ccc;
    font-size: 12px;
    color: #969696;
    line-height: 30px;
    padding: 5px 0 5px 16px;
    background-color: #fff;
    display: block;
    float: left;
    margin: 14px 10px 0 0;
}
.enquiry_bottom_bar .enquiry_bar_con .enquiry_bar_data select option {
    line-height: 30px;
    font-size: 12px;
    color: #969696;
}
.enquiry_bottom_bar .enquiry_bar_con .enquiry_bar_data .btn {
    width: 135px;
    height: 32px;
    display: block;
    background-color: #e64543;
    color: #fff;
    font-size: 18px;
    font-family: Microsoft YaHei;
    text-align: center;
    line-height: 32px;
    float: left;
    margin: 14px 0 0 10px;
    cursor: pointer;
}
.enquiry_bottom_bar .enquiry_bar_con .enquiry_bar_data .close {
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    float: left;
    margin: 14px 0 0 5px;
    cursor: pointer;
    background-color: #cf0000;
}
.enquiry_bottom_bar .enquiry_bar_con .enquiry_bar_data .close span {
    display: block;
    width: 16px;
    height: 16px;
    background: url(http://y1.ifengimg.com/auto/image/2014/0827/b.png) no-repeat -114px -6px;
    margin: 8px auto 0;
}
