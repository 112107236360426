.tabsNews {
    float: left;
    margin-bottom: 20px;
    & :global {
        & .news-stream-basic-more {
            color: #999;
        }
        & .news_item {
            &:hover {
                background-color: transparent;
                & :global(.news-stream-newsStream-news-item-title) a {
                    color: #222;
                }
            }
        }
        & .news-stream-newsStream-news-item-infor {
            width: 432px;
        }
        & .news-stream-newsStream-image-link {
            margin-left: -218px;
            width: 198px;
            height: 112px;
        }
        & .news-stream-newsStream-news-item-title:hover a {
            color: #f54343 !important;
            text-decoration: underline;
        }
        & .news-stream-newsStream-image-link-3 {
            width: 198px;
            height: 112px;
        }
        & .news-stream-newsStream-mr11 {
            margin-right: 28px;
        }
        & .news-stream-newsStream-news-item-has-image {
            padding: 20px 0 20px 218px;
        }
        & .news-stream-newsStream-news-item-title-height {
            margin-top: 28px;
            height: 48px;
        }
        & .news-stream-newsStream-text {
            font-size: 14px;
        }
    }
}
.cut {
    height: 26px;
    width: 8px;
    background-color: #d7524b;
    margin-right: 15px;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    margin-top: 12px;
}
.tabs {
    height: 50px;
    width: 1090px;
    border-bottom: 2px solid #ececec;
    padding-left: 8px;
    & li {
        float: left;
        margin-right: 27px;
        min-width: 62px;
        padding: 0 10px;
        font: 22px '微软雅黑';
        line-height: 50px;
        color: #666;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        text-align: center;
        color: #3b3732;
        font-weight: bold;
        &.itis {
            color: #f54343;
            border-bottom: 2px solid #f54343;
        }
    }
}
