.nav_box {
    margin-bottom: 20px;
    background-color: #f54343; /* 变量 */
    height: 38px;
}
.nav,
.nav li {
    background-image: url('./shu.png');
    background-repeat: repeat-y;
    position: relative;
}
.nav {
    margin: 0 auto;
    padding-left: 2px;
    width: 1090px;
    height: 100%;
    font: 14px '微软雅黑';
    background-position: left top;
    display: flex;
    & li {
        border-top: 1px solid #f54343;
        border-bottom: 1px solid #f54343;
        line-height: 36px;
        background-position: right top;
        flex: 1;
        display: flex;
        justify-content: center;
    }
    & .nav_item {
        color: #fff; /* 变量 */
        &:hover {
            color: #ffc201; /* 变量 */
            text-decoration: none;
        }
    }
}
.nav .last {
    /* background: none;
    padding: 0 32px; */
    &:hover {
        background-color: #fff;
        & .nav_item {
            color: #f54343; /* 变量 */
            text-decoration: none;
        }
        & i {
            background: url(//y3.ifengimg.com/auto/image/2015/0727/s_icon2.png) no-repeat -51px 0;
        }
    }
}
.subnav_box {
    position: absolute;
    top: 37px;
    left: 0;
    background-color: #f7f5f6;
    z-index: 1000;
    display: block;
}
.subnav_box_hide {
    display: none;
}
.nav .subnav_box li {
    background: none;
    border: none;
    padding: 0 12px 0 13px;
    &:hover {
        background-color: #f54343;
        & a {
            color: #fff;
            text-decoration: none;
        }
    }
}
.nav i {
    display: inline-block;
    zoom: 1;
    width: 9px;
    height: 6px;
    background: url(//y3.ifengimg.com/auto/image/2015/0908/dw2.png) no-repeat 0 0;
    margin-left: 4px;
}
.subnav_box li a {
    color: #000;
}
