.arl_dl {
    border: solid 1px #c7c7c7;
    text-align: left;
    margin-bottom: 15px;
}
.arl_d_md {
    position: relative;
    height: 110px;
    background-color: #f2f1f1;
    padding: 17px 12px;
    border-bottom: dotted 1px #c7c7c7;
}
.arl_d_md .pic {
    float: left;
    height: 100px;
    width: 150px;
}
.arl_d_md_info {
    float: right;
    width: 458px;
}
.arl_d_md_info .name {
    width: 173px;
    font-weight: 700;
    margin-top: 12px;
    float: left;
}
.price {
    width: 285px;
    float: left;
}
.price a:hover {
    color: #ef2626;
    text-decoration: none;
}
.price b {
    font-size: 24px;
    color: #f54343;
}
.emissions {
    height: 16px;
    overflow: hidden;
    width: 173px;
    margin-top: 20px;
    float: left;
}
.emissions label {
    color: #666666;
}
.transmission {
    margin-top: 20px;
    float: left;
}
.transmission label {
    color: #666666;
}
.link {
    clear: both;
    padding-top: 16px;
}
.link a {
    height: 25px;
    line-height: 23px;
    border: solid 1px #c7c7c7;
    display: inline-block;
    margin-right: 7px;
}
.link a span {
    border: solid 1px #fff;
    display: block;
    padding: 0 7px;
    cursor: pointer;
}
.edges_tl {
    position: absolute;
    left: -1px;
    top: -1px;
    height: 15px;
    width: 15px;
    display: block;
    /* background-position: -67px -92px; */
    /* background-image: url(http://img.ifeng.com/tres/auto/9/2013/0812/334_4955956228_20130812114633.png);
    background-repeat: no-repeat; */
}
.edges_br {
    position: absolute;
    right: -1px;
    bottom: -1px;
    height: 15px;
    width: 15px;
    display: block;
    /* background-position: -85px -92px;
    background-image: url(http://img.ifeng.com/tres/auto/9/2013/0812/334_4955956228_20130812114633.png);
    background-repeat: no-repeat; */
}

.arl_pro_tit {
    height: 32px;
    line-height: 32px;
    background-color: #c7c7c7;
    position: relative;
}
.arl_pro_tit h3 {
    float: left;
    display: block;
    background-color: #f54343;
    color: #ffffff;
    padding: 0 11px;
    font-size: 14px;
    border-right: solid 1px #a3a2a2;
}
.siwtch_t {
    border-right: solid 1px #e4e3e3;
    float: left;
}
.siwtch_t li {
    float: left;
    padding: 0 13px;
    border-left: solid 1px #e4e3e3;
    border-right: solid 1px #a3a2a2;
    cursor: pointer;
}
.siwtch_t .on {
    float: left;
    padding: 0 13px;
    border-left: solid 1px #e4e3e3;
    border-right: solid 1px #a3a2a2;
    cursor: pointer;
    background-color: #fff;
    border-top: solid 2px #f54343;
    position: relative;
    margin-top: -1px;
}
.arl_pro_city {
    float: right;
    margin-right: 130px;
}
.arl_pro_city .on {
    float: left;
}
.nav_link {
    float: left;
    z-index: 99;
    position: relative;
}
.btn {
    width: auto;
    max-width: 53px;
    height: 26px;
    line-height: 26px;
    font-size: 12px;
    font-family: SimSun;
    font-weight: bold;
    color: #969696;
    float: left;
    text-align: left;
    margin-left: 15px;
    position: relative;
    cursor: pointer;
    z-index: 1000;
}
.btn span {
    display: inline-block;
    zoom: 1;
    line-height: 26px;
    vertical-align: middle;
}
.btn span a {
    color: #969696;
    font-size: 12px;
    font-family: SimSun;
}
.btn i {
    display: block;
    width: 9px;
    height: 6px;
    position: absolute;
    top: 13px;
    right: -10px;
    background: url(//y3.ifengimg.com/auto/image/2015/0727/s_icon2.png) no-repeat -51px 0;
}
.cx_city_n {
    position: absolute;
    padding: 3px;
    left: 0;
    top: 20px;
}
.cx_city_hide {
    display: none;
    position: absolute;
    padding: 3px;
    left: 0;
    top: 20px;
}
.cx_city_n .close,
.cx_city_hide .close {
    width: 34px;
    height: 37px;
    position: absolute;
    top: 0;
    right: -30px;
    cursor: pointer;
    overflow: hidden;
    text-indent: -9999px;
    background: url(//p0.ifengimg.com/auto/image/2018/0731/334_4957197228_20131017161936.png) no-repeat 0 -11px;
}
.cx_city_n .arrow,
.cx_city_hide .arrow {
    width: 15px;
    height: 10px;
    position: absolute;
    left: 20px;
    top: -6px;
    overflow: hidden;
    text-indent: -9999px;
    background: url(//p0.ifengimg.com/auto/image/2018/0731/334_4957197228_20131017161936.png) no-repeat 0 0;
}
.cx_city_br {
    border: solid 1px #ccc;
    background-color: #fff;
    width: 289px;
    margin-top: 12px;
}
.cx_city_le {
    overflow: hidden;
    padding: 8px 5px;
}
.cx_city_le ul li {
    float: left;
    margin: 2px 4px;
}
.cx_city_le ul li a {
    text-align: center;
    display: block;
    height: 19px;
    width: 18px;
    line-height: 19px;
    font-size: 14px;
    font-family: 'Arial';
    color: #969696;
    border: 0;
    background-color: #fff;
}
.cx_city_le a.a_all_city {
    font-size: 12px;
    width: 52px;
    font-family: SimSun;
    color: #ababaf;
}
.cx_city_le a:hover {
    color: #fff;
    background-color: #cd0000;
    border-color: #b00203;
    text-decoration: none;
}
.cx_city_nlst {
    height: 148px;
    overflow-y: scroll;
}
.cx_city_nlst dl {
    overflow: hidden;
    text-align: left;
    margin: 0;
    padding: 0;
    padding-left: 25px;
    line-height: 36px;
    border-top: 1px dotted #545454;
}
.cx_city_nlst .cbg {
    background-color: #fff;
}
.cx_city_nlst dt {
    float: left;
    display: inline;
    width: 25px;
    margin: 0;
    padding: 0;
    margin-left: -25px;
    color: #969696;
    text-align: right;
    font-size: 14px;
    font-family: 'Arial';
}
.cx_city_nlst dd {
    float: left;
    display: inline;
    margin: 0;
    padding: 0;
    margin-right: 10px;
}
.cx_city_nlst dd a {
    display: block;
    line-height: 36px;
    padding: 0 10px;
    color: #969696;
    font-size: 12px;
    font-family: SimSun;
    white-space: nowrap;
}
.siwtch_content {
    position: relative;
}

.siwtch_layout_hide {
    position: relative;
    display: none;
}
.siwtch_layout_show {
    position: relative;
    display: block;
}
.arl_pro_tit .morejiangjia {
    position: absolute;
    right: 10px;
    height: 32px;
    line-height: 32px;
    display: block;
}
.siwtch_content .sale li {
    overflow: hidden;
    border-bottom: dotted 1px #c7c7c7;
    clear: both;
    padding: 0 7px;
    height: 1%;
}
.siwtch_content .pb_l1 {
    line-height: 30px;
    width: 392px;
    position: relative;
    float: left;
}
.siwtch_content .name {
    font-weight: 700;
}
.siwtch_content b {
    height: 14px;
    line-height: 14px;
    width: 21px;
    color: #ffffff;
    font-weight: 100;
    background-color: #f54343;
    display: inline-block;
    text-align: center;
    margin: 0 5px;
    vertical-align: text-top;
    line-height: 16px;
    height: 15px;
    padding-top: 1px;
    font-size: 12px;
    border: 1px solid #f54343;
}
.siwtch_content .dl {
    color: #666666;
    display: inline-block;
    margin-right: 5px;
}
.siwtch_content .tel {
    font-weight: 700;
    color: #063;
    font-size: 12px;
    font-family: 'Verdana';
}
.pb_l2 {
    width: 75px;
    line-height: 30px;
    float: left;
}
.pb_l2 em {
    display: block;
}
.pb_l2 .lt {
    text-decoration: line-through;
}
.pb_l2 .red {
    color: #f54343;
}
.pb_l3 {
    width: 75px;
    line-height: 30px;
    float: left;
}
.pb_l3 .ask {
    vertical-align: text-top;
    line-height: 16px;
    height: 15px;
    padding: 1px 3px;
    color: #ffffff;
    font-weight: 100;
    background-color: #f54343;
    display: inline-block;
    text-align: center;
    margin: 15px 5px 0;
    font-size: 12px;
    border: 1px solid #f54343;
}
