.img_ad_b {
    width: 1090px;
    overflow: hidden;
    margin-bottom: 10px;
}
.img_ad_b ul {
    font-size: 0;
    width: 1110px;
    text-align: left;
}
.img_ad_b ul li {
    width: 259px;
    height: 25px;
    display: inline-block;
    zoom: 1;
    margin-right: 10px;
    background-color: #fff;
    text-align: center;
    line-height: 25px;
    border: 1px solid #a1a1a1;
    padding: 2px;
    color: #a6a6a6;
}
.img_ad_b ul li a {
    color: #a1a1a1;
    font-size: 12px;
    font-family: Microsoft YaHei;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
}
