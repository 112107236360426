.main_nav {
    width: 1070px;
    height: 26px;
    margin: 0 auto;
    padding: 7px 10px 3px;
    font-family: '宋体';
    position: relative;
    z-index: 999;
    background: #fff;
}

.user {
    float: right;
    text-align: right;
}

.box {
    width: 1090px;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    padding: 0;
    margin: 0;
}

.ad {
    width: 1090px;
    margin: 0 auto;
}

.search {
    width: 1090px;
    margin: 0 auto;
    height: 26px;
    padding-top: 28px;
    padding-bottom: 10px;
    background: #fff;
}

.logo {
    float: left;
    font-size: 12px;
    & a img {
        position: relative;
        left: 0;
        top: 0;
        right: 361px;
        float: left;
    }
}
.cheshangtong {
    color: #2b2b2b;
    vertical-align: middle;
    float: right;
    margin-right: 10px;
    line-height: 24px;
    &:hover {
        color: #f54343;
    }
}
.citys {
    display: inline-block;
}
