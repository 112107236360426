/* 列表 */
.news_list {
    height: 100%;
}
.news_item_has_image {
    padding: 20px 13px 20px 164px;
}
.news_item_no_image,
.news_item_3_image {
    padding: 20px 13px 20px 0;
}
.news_item_has_image,
.news_item_no_image,
.news_item_3_image {
    border-bottom: 1px solid #e7e7e7;
    font-family: '微软雅黑';
    &:hover {
        background-color: #f6f6f6;
        & h2 a {
            color: #ff2f42; /* 变量 */
            text-decoration: none;
        }
        & .image_link {
            & img {
                -webkit-transform: scale(1.1, 1.1);
                transform: scale(1.1, 1.1);
            }
        }
    }
}
.ad {
    padding: 20px 0;
    font-family: '微软雅黑';
    border-bottom: 1px solid #e7e7e7;
}
/* 大图 */
.news_item_big_image {
    border-bottom: 1px solid #e7e7e7;
    font-family: '微软雅黑';
}
.news_item_big_image_title {
    font-size: 18px;
    height: 64px;
    line-height: 64px;
    font-weight: bold;
    overflow: hidden;
    & a {
        color: #222;
        &:hover {
            color: #ff2f42; /* 变量 */
            text-decoration: none;
        }
    }
}
.big_image_link {
    display: block;
    margin-bottom: 8px;
    & img {
        width: 100%;
        height: 100%;
    }
}
.big_image_tools {
    margin-bottom: 20px;
}

.image_link,
.news_item_infor {
    float: left;
}
.image_link,
.image_link_3 {
    position: relative;
    display: block;
    overflow: hidden;
    & img {
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
}
.image_link_3 {
    &:hover img {
        -webkit-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
    }
}
.image_link {
    margin-left: -164px;
    width: 144px;
    height: 96px;
    &:hover .play {
        opacity: 1;
    }
}
.image_link_3 {
    float: left;
    width: 203px;
    height: 130px;
}
.play,
.tuji {
    position: absolute;
    z-index: 1;
}
.play {
    left: 50%;
    top: 50%;
    width: 48px;
    height: 48px;
    margin: -24px 0 0 -24px;
    background-image: url('./playHover.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    opacity: 0.7;
}
.tuji {
    top: 0;
    right: 0;
    padding: 2px 4px;
    font: 12px simsun;
    border: 1px solid #c62f32;
    color: #fff;
    background-color: #ee3c40;
}
.mr11 {
    margin-right: 8px;
}
.top_11 {
    margin-top: 11px;
}
.news_item_infor {
    width: 100%;
}
.news_item_title {
    height: 52px;
    font-size: 18px;
    line-height: 26px;
    font-weight: bold;
    overflow: hidden;
    & a {
        color: #222222;
        text-decoration: none;
    }
}
.newsstreamSecondEdit {
    position: absolute;
    top: 123px;
    height: 123px;
    width: 698px;
    overflow: hidden;
    z-index: -1;
}
.newsstreamFourthEdit {
    position: absolute;
    top: 620px;
    height: 123px;
    width: 698px;
    overflow: hidden;
    z-index: -1;
}
.newsstreamSixthEdit {
    position: absolute;
    top: 880px;
    height: 123px;
    width: 698px;
    overflow: hidden;
    z-index: -1;
}
.newsstreamTxtEdit {
    position: absolute;
    top: 418px;
    height: 30px;
    width: 460px;
    right: 0px;
    overflow: hidden;
    z-index: -1;
}
.time {
    margin-top: 14px;
}
.mb34 {
    margin-bottom: 34px;
}
.mb16 {
    margin-bottom: 16px;
}
/* 标签 */
.text,
a.ly {
    font-size: 12px;
    color: #999;
    font-family: '微软雅黑';
}
.mr10 {
    margin-right: 10px;
}
a.ly {
    float: right;
    display: block;
    margin-right: 10px;
    padding-left: 27px;
    text-decoration: none;
    background-image: url('./ly.png');
    background-repeat: no-repeat;
    background-position: left center;
    *position: relative;
    *top: -16px;
    &:hover {
        background-image: url('./lyHover.png');
        color: #ff2f42; /* 变量 */
        text-decoration: none;
    }
}
.more_box {
    margin-top: 27px;
    text-align: center;
}
.more,
.is_end {
    display: inline-block;
    font-size: 14px;
    color: #999;
}
.is_end {
    padding: 3px;
}
.more {
    padding: 3px 20px 3px 3px;
    background: url('./more.jpg') no-repeat center right;
    cursor: pointer;
    &:hover {
        color: #999;
        text-decoration: none;
    }
}

/* 图文文字链 */
.div_txt_list {
    width: 100%;
    border-bottom: 1px solid #f6f5f5;
    text-align: center;
}

.ul_txt_list {
    margin: 11px 0 14px 0px;
}

.li_txt_list {
    width: 200px;
    margin-left: 10px;
    font-size: 14px;
    color: #515151;
    text-align: left;
    line-height: 32px;
    display: inline-block;
    zoom: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.a_txt_list {
    font-size: 14px;
    color: #515151;
    text-decoration: none;
}

/* 热车排行 */
.div_hotcar {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-bottom: 1px solid #f6f5f5;
    text-align: center;
    padding: 8px 0;
}

.ul_hotcar {
    width: 100%;
    text-align: left;
    margin: 0;
    padding: 0;
}

.li_hotcar {
    width: 113px;
    margin: 0 4px 0 17px;
    position: relative;
    display: inline-block;
    zoom: 1;
    text-align: center;
}

.a_hotcar {
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.img_hotcar {
    width: 100px;
    height: 66px;
    border: 0;
}

.img_hotcar {
    width: 100px;
    height: 66px;
    border: 0;
}

.span_hotcar {
    display: block;
    width: 100%;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* 车型图片推荐 */
.div_typecar {
    width: 100%;
    background-color: #f6f5f5;
    border-bottom: 1px solid #f6f5f5;
    text-align: center;
}

.art_tit_b {
    padding: 15px 17px 17px;
}

.art_tit {
    width: 50%;
    float: left;
    text-align: left;
}

.art_tit a {
    color: #e64543;
    font-size: 18px;
    text-align: left;
    text-decoration: none;
}

.art_pri {
    width: 50%;
    float: left;
    text-align: right;
    color: #969696;
    font-size: 14px;
}

.art_pri span {
    display: inline-block;
    zoom: 1;
    vertical-align: bottom;
}

.pri {
    color: #e64543;
    font-size: 18px;
    font-weight: bold;
}

.inq_btn {
    line-height: 22px;
    color: #fff;
    font-size: 12px;
    font-family: SimSun;
    background-color: #e64543;
    cursor: pointer;
    padding: 0 7px 0 8px;
    vertical-align: middle;
}

.clear {
    clear: both;
}

.ul_typecar {
    margin-bottom: 18px;
    margin: 0;
    padding: 0;
    text-align: center;
    height: 120px;
}

.li_typecar {
    width: 150px;
    float: left;
    margin-left: 18px;
    margin-right: 3px;
}

.image_wh {
    width: 144px;
    height: 96px;
}

.image_zxsc {
    width: 150px;
    height: 100px;
}

.banner_ad_div {
    width: 698px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: -20px;
}

.banner_ad {
}
