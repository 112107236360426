body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
p,
blockquote,
th,
td {
    margin: 0;
    padding: 0;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
fieldset,
img {
    border: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
    font-style: normal;
    font-weight: normal;
}
ol,
ul {
    list-style: none;
}
caption,
th {
    text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
}
q:before,
q:after {
    content: '';
}
body {
    font-size: 12px;
    font-family: Microsoft YaHei, simsun, arial, helvetica, clean, sans-serif;
    background: #fff;
    color: #2b2b2b;
}
table {
    font-size: inherit;
    font: 100%;
}
h1 {
    font-size: 14px;
}
h2 {
    font-size: 14px;
    font-weight: normal;
}
h3 {
    font-size: 12px;
}
h4 {
    font-size: 12px;
    font-weight: normal;
}
strong {
    font-weight: bold;
}
a {
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
a:link {
    color: #000;
}
a:visited {
    color: #000;
}
a:hover {
    color: inherit;
}
a:active {
    color: #000;
}

:global {
    & .clear {
        clear: both;
    }
    & .clearfix {
        zoom: 1;
    }
    & .clearfix:after {
        content: '.';
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
    .w1090 {
        width: 1090px;
    }
    & .img_l {
        width: 512px;
        left: 50px;
        float: left;
    }
    & .img_l .img {
        width: 100%;
        height: 340px;
        overflow: hidden;
        position: relative;
    }
    & .img_l .img img {
        width: 100%;
        height: 340px;
        overflow: hidden;
        position: relative;
    }
    & .img_l .img .t_m {
        width: 100%;
        height: 36px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /*FireFox*/
        background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Opear */
        background: -ms-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); /*IE10及IE10+*/
        filter: progid:DXImageTransform.Microsoft.alpha(Opacity=0, FinishOpacity=100, Style=0, StartX=0, StartY=0, FinishX=1200, FinishY=80);
    }
    & .img_l .t_b {
        width: 100%;
        height: 71px;
        background-color: #f6f5f5;
        position: relative;
        margin-top: 12px;
        text-align: center;
    }
    & .img_l .t_b h2,
    & .img_l .t_b h2 a {
        display: block;
        font-size: 24px;
        color: #e64543;
        font-weight: bold;
        line-height: 38px;
        line-height: 38px;
        width: 100%;
        height: 38px;
        overflow: hidden;
    }
    & .img_l .t_b h2 a:hover {
        text-decoration: none;
    }
    & .img_r .t_b h2 a:hover {
        text-decoration: none;
    }
    & .img_l .t_b i {
        display: block;
        width: 11px;
        height: 11px;
        background: url(//y3.ifengimg.com/auto/image/2015/0727/s_icon2.png) no-repeat -49px -64px;
        position: absolute;
        top: 0;
        right: 0;
    }
    & .img_l .img .img_t {
        width: 100%;
        height: 36px;
        font-size: 12px;
        color: #fff;
        line-height: 34px;
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
    }
    & .img_l .img .t_m {
        width: 100%;
        height: 36px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /*FireFox*/
        background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Opear */
        background: -ms-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); /*IE10及IE10+*/
        filter: progid:DXImageTransform.Microsoft.alpha(Opacity=0, FinishOpacity=100, Style=0, StartX=0, StartY=0, FinishX=1200, FinishY=80);
    }
    & .img_m {
        width: 310px;
        float: left;
        margin: 0 10px;
    }
    & .img_m .img {
        width: 100%;
        height: 206px;
        overflow: hidden;
        position: relative;
        margin-bottom: 10px;
    }
    & .img_m img {
        width: 100%;
    }
    & .img_m .img .img_t {
        width: 100%;
        height: 36px;
        font-size: 12px;
        color: #fff;
        line-height: 34px;
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
    }
    & .img_m .img_b .imgt {
        width: 100%;
        height: 30px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        line-height: 29px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
    }
    & .img_m .img_b .imgm {
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /*FireFox*/
        background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Opear */
        background: -ms-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); /*IE10及IE10+*/
        filter: progid:DXImageTransform.Microsoft.alpha(Opacity=0, FinishOpacity=100, Style=0, StartX=0, StartY=0, FinishX=1200, FinishY=80);
    }
    & .img_m .img_b {
        width: 250px;
        height: 168px;
        overflow: hidden;
        position: relative;
        margin: 0 auto;
    }
    & .img_m .img_b_s {
        width: 100%;
        height: 33px;
        line-height: 31px;
        font-size: 0;
        text-align: center;
    }
    & .img_m .img_b_s span {
        color: #9d9d9d;
        font-size: 14px;
        display: inline-block;
        +display: inline;
        zoom: 1;
        margin-right: 15px;
    }
    & .img_m .img_b_s span .r {
        color: #e64543;
        font-weight: bold;
        font-size: 14px;
        margin: 0;
    }
    & .img_m .img_b_s span .g {
        color: #2e9402;
        font-weight: bold;
        font-size: 14px;
        margin: 0;
    }
    & .img_m .char_lst {
        width: 100%;
        background-color: #f6f4f5;
        position: relative;
    }
    & .img_m .char_lst ul {
        width: 100%;
        padding: 12px 0 11px;
        margin-top: 10px;
        position: inherit;
    }
    & .img_m .char_lst ul li {
        width: 290px;
        height: 32px;
        padding-left: 10px;
        font-size: 0;
        background-color: #f6f4f5;
        position: relative;
    }
    & .img_m .char_lst ul li a {
        width: 140px;
        height: 30px;
        line-height: 28px;
        display: inline-block;
        zoom: 1;
        margin-left: 3px;
        font-size: 14px;
        color: #000;
        overflow: hidden;
        text-align: left;
    }
    & .img_m .img .t_m {
        width: 100%;
        height: 36px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /*FireFox*/
        background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Opear */
        background: -ms-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); /*IE10及IE10+*/
        filter: progid:DXImageTransform.Microsoft.alpha(Opacity=0, FinishOpacity=100, Style=0, StartX=0, StartY=0, FinishX=1200, FinishY=80);
    }
    & .img_r {
        width: 246px;
        float: left;
    }
    & .img_r .t_b {
        width: 100%;
        height: 70px;
        background-color: #f6f5f5;
        position: relative;
        margin-bottom: 12px;
        text-align: center;
    }
    & .img_r .t_b h2,
    & .img_r .t_b h2 a {
        display: block;
        font-size: 24px;
        color: #e64543;
        font-weight: bold;
        line-height: 38px;
        width: 100%;
        height: 38px;
        overflow: hidden;
    }
    & .img_r .t_b i {
        display: block;
        width: 11px;
        height: 11px;
        background: url(//y3.ifengimg.com/auto/image/2015/0727/s_icon2.png) no-repeat -49px -64px;
        position: absolute;
        top: 0;
        right: 0;
    }
    & .img_r .img {
        width: 100%;
        height: 165px;
        overflow: hidden;
        position: relative;
        margin-bottom: 10px;
    }
    & .img_r .img img {
        width: 100%;
        height: 100%;
    }
    & .img_r .img .img_t {
        width: 100%;
        height: 36px;
        font-size: 12px;

        color: #fff;
        line-height: 34px;
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
    }
    & .img_r .img .t_m {
        width: 100%;
        height: 36px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /*FireFox*/
        background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Opear */
        background: -ms-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); /*IE10及IE10+*/
        filter: progid:DXImageTransform.Microsoft.alpha(Opacity=0, FinishOpacity=100, Style=0, StartX=0, StartY=0, FinishX=1200, FinishY=80);
    }
    & .img_r .img .img_t {
        width: 100%;
        height: 36px;
        font-size: 12px;

        color: #fff;
        line-height: 34px;
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
    }
    & .img_r .img .t_m {
        width: 100%;
        height: 36px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /*FireFox*/
        background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Opear */
        background: -ms-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); /*IE10及IE10+*/
        filter: progid:DXImageTransform.Microsoft.alpha(Opacity=0, FinishOpacity=100, Style=0, StartX=0, StartY=0, FinishX=1200, FinishY=80);
    }

    & .float_img_b {
        width: 250px;
        height: 200px;
        overflow: hidden;
        position: absolute;
        top: 33px;
        left: 495px;
        z-index: 9;
        text-align: center;
        background-color: #fff;
    }
    & .float_img_b .img_b {
        width: 250px;
        height: 167px;
        overflow: hidden;
        position: relative;
        margin: 0;
    }
    & .float_img_b .img_b img {
        width: 250px;
    }
    & .float_img_b .img_b_s {
        width: 100%;
        height: 33px;
        line-height: 31px;
        font-size: 0;
    }
    & .float_img_b .img_b_s span {
        color: #9d9d9d;
        font-size: 14px;
        display: inline-block;
        zoom: 1;
        margin-right: 15px;
    }
    & .float_img_b .img_b_s span .r {
        color: #e64543;
        font-weight: bold;
        font-size: 14px;
        margin: 0;
    }
    & .float_img_b .img_b_s span .g {
        color: #2e9402;
        font-weight: bold;
        font-size: 14px;
        margin: 0;
    }
}
