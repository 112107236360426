.title {
    color: #1b1b1b;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 0;
    font-family: Microsoft YaHei;
    overflow: hidden;
}
.cut {
    height: 21px;
    width: 4px;
    background-color: #fc7a32;
    margin-right: 15px;
    display: inline-block;
    vertical-align: middle;
}

.title_l {
    width: 50%;
    float: left;
    text-align: left;
    line-height: 27px;
}
.title_l span {
    vertical-align: middle;
}
.title_l .middle {
    margin-left: 15px;
    font-size: 14px;
    color: #a2a2a2;
    font-weight: normal;
    vertical-align: sub;
}
.title_l .middle a {
    color: #a2a2a2;
}
.title_l .middle a:hover {
    color: #a2a2a2;
}
.title_r {
    width: 50%;
    float: left;
    text-align: right;
    font-size: 14px;
    font-weight: normal;
    line-height: 27px;
}
.title_r span {
    vertical-align: sub;
}
.title_r span a {
    color: #a2a2a2;
}
/* .title_r span a:hover {
   text-decoration: none;

} */
