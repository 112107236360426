.info {
    margin-top: 25px;
    width: 698px;
}

.hardAd {
    width: 640px;
    padding: 0px;
    margin: 18px 0 10px;
    text-align: center;
}
