.auto_video {
    margin-top: 20px;
}
.auto_title {
    display: block;
    text-align: left;
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    overflow: hidden;
    height: 43px;
    line-height: 23px;
}

.auto_ul {
    overflow: hidden;
    list-style: none;
    position: relative;
    width: 1090px;
    margin-top: 22px;
}

.auto_ul_li {
    float: left;
    display: inline;
    margin: 0 0 9px 11px;
    position: relative;
    width: 238px;
}

.auto_ul_li a {
    color: #333;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
}

.auto_ul_li a:hover {
    color: #f54343;
}

.auto_ul_li a img {
    width: 238px;
    height: 134px;
}

.auto_first {
    margin: 0 1px 0 0;
    width: 575px;
    float: left;
    display: inline;
    position: relative;
}

.auto_first a {
    color: #333;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
}

.auto_first a:hover {
    color: #f54343;
}

.auto_first a img {
    width: 575px;
    height: 323px;
}
.auto_first .auto_title {
    font-size: 16px;
    margin-top: 15px;
    text-align: center;
}

.auto_first_icon_video {
    background-image: url(//ueimg.3conline.com/pcauto/pc/index/w/video/1.0.0/video_z_d6da377.png);
    background-position: 0px -33px;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    position: absolute;
    left: 10px;
    bottom: 70px;
}

.auto_icon_video {
    background-image: url(//ueimg.3conline.com/pcauto/pc/index/w/video/1.0.0/video_z_d6da377.png);
    background-position: -20px 0px;
    position: absolute;
    left: 10px;
    bottom: 52px;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
}

.auto_h2 {
    display: inline-block;
    zoom: 1;
    color: #e64543;
    font-size: 30px;
}
