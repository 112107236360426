.bannerPic {
    width: 1090px;
    position: relative;
    overflow: hidden;
    margin-top: 27px;
    padding-bottom: 60px;
    & [ifeng_ui_pc_sliders-dots] {
        position: absolute;
        right: 5px;
        bottom: -16px;
        height: 8x;
        line-height: 4px;
        z-index: 10;
        left: 0;
        text-align: center;
        font-size: 0;
    }
}
.content {
    overflow: hidden;
}
.dot_style {
    width: 12px !important;
    height: 8px !important;
    background-color: #959595 !important;
    margin-right: 10px !important;
}

.current {
    width: 55px !important;
    height: 8px !important;
    margin-right: 10px !important;
    background: #e5524b !important;
}

.pic_img {
    width: 400px;
    height: 230px;
    overflow: hidden;
    & img {
        display: block;
    }
}
.t_b_stitle{
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.trans {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-backface-visibility: hidden;
}

.trans:hover {
    transform: scale(1.04, 1.04);
    -ms-transform: scale(1.04, 1.04);
    -webkit-transform: scale(1.04, 1.04);
}

.text {
    width: 400px;
    height: 34px;
    background: #000;
    position: absolute;
    bottom: 0px;
    filter: alpha(opacity=75);
    -moz-opacity: 0.75;
    opacity: 0.75;
}

.title {
    font-size: 14px;
    text-indent: 10px;
    width: 300px;
    height: 34px;
    line-height: 34px;
    position: absolute;
    bottom: 0px;
    & a {
        color: #fff;
        font-family: '微软雅黑';
        font-size: 14px;
    }
}

.img_l {
    width: 512px;
    float: left;
}
.img_l .img {
    width: 100%;
    height: 340px;
    overflow: hidden;
    position: relative;
}
.img_l .img img {
    width: 100%;
    height: 340px;
    overflow: hidden;
    position: relative;
}
.img_l .img .t_m {
    width: 100%;
    height: 36px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /*FireFox*/
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Opear */
    background: -ms-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); /*IE10及IE10+*/
    filter: progid:DXImageTransform.Microsoft.alpha(Opacity=0, FinishOpacity=100, Style=0, StartX=0, StartY=0, FinishX=1200, FinishY=80);
}
.img_l .t_b {
    width: 100%;
    height: 71px;
    background-color: #f6f5f5;
    position: relative;
    margin-top: 12px;
    text-align: center;
}
.img_l .t_b h2,
.img_l .t_b h2 a {
    display: block;
    font-size: 24px;
    color: #e64543;
    font-weight: bold;
    line-height: 38px;
    width: 100%;
    height: 38px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    
}
.img_l .t_b h2 a:hover {
    text-decoration: none;
}
.img_r .t_b h2 a:hover {
    text-decoration: none;
}
.img_l .t_b i {
    display: block;
    width: 11px;
    height: 11px;
    background: url(//y3.ifengimg.com/auto/image/2015/0727/s_icon2.png) no-repeat -49px -64px;
    position: absolute;
    top: 0;
    right: 0;
}
.img_l .img .img_t {
    width: 100%;
    height: 36px;
    font-size: 12px;
    font-family: SimSun;
    color: #fff;
    line-height: 34px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
}
.img_l .img .t_m {
    width: 100%;
    height: 36px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /*FireFox*/
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Opear */
    background: -ms-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); /*IE10及IE10+*/
    filter: progid:DXImageTransform.Microsoft.alpha(Opacity=0, FinishOpacity=100, Style=0, StartX=0, StartY=0, FinishX=1200, FinishY=80);
}
.img_m {
    width: 310px;
    float: left;
    margin: 0 10px;
}
.img_m .img {
    width: 100%;
    height: 206px;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
}
.img_m img {
    width: 100%;
}
.img_m .img .img_t {
    width: 100%;
    height: 36px;
    font-size: 12px;
    font-family: SimSun;
    color: #fff;
    line-height: 34px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
}
.img_m .img .t_m {
    width: 100%;
    height: 36px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /*FireFox*/
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Opear */
    background: -ms-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); /*IE10及IE10+*/
    filter: progid:DXImageTransform.Microsoft.alpha(Opacity=0, FinishOpacity=100, Style=0, StartX=0, StartY=0, FinishX=1200, FinishY=80);
}
.img_r {
    width: 246px;
    float: left;
}
.img_r .t_b {
    width: 100%;
    height: 70px;
    background-color: #f6f5f5;
    position: relative;
    margin-bottom: 12px;
    text-align: center;
}
.img_r .t_b h2,
.img_r .t_b h2 a {
    display: block;
    font-size: 24px;
    color: #e64543;
    font-weight: bold;
    line-height: 38px;
    width: 100%;
    height: 38px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.text_a_font{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}
.img_r .t_b i {
    display: block;
    width: 11px;
    height: 11px;
    background: url(//y3.ifengimg.com/auto/image/2015/0727/s_icon2.png) no-repeat -49px -64px;
    position: absolute;
    top: 0;
    right: 0;
}
.img_r .img {
    width: 100%;
    height: 165px;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
}
.img_r .img img {
    width: 100%;
    height: 100%;
}
.img_r .img .img_t {
    width: 100%;
    height: 36px;
    font-size: 12px;
    font-family: SimSun;
    color: #fff;
    line-height: 34px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
}
.img_r .img .t_m {
    width: 100%;
    height: 36px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /*FireFox*/
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Opear */
    background: -ms-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); /*IE10及IE10+*/
    filter: progid:DXImageTransform.Microsoft.alpha(Opacity=0, FinishOpacity=100, Style=0, StartX=0, StartY=0, FinishX=1200, FinishY=80);
}
.img_r .img .img_t {
    width: 100%;
    height: 36px;
    font-size: 12px;
    font-family: SimSun;
    color: #fff;
    line-height: 34px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
}
.img_r .img .t_m {
    width: 100%;
    height: 36px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /*FireFox*/
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Opear */
    background: -ms-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); /*IE10及IE10+*/
    filter: progid:DXImageTransform.Microsoft.alpha(Opacity=0, FinishOpacity=100, Style=0, StartX=0, StartY=0, FinishX=1200, FinishY=80);
}


